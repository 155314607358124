<!--
	Copyright 2023 HALBY
	See LICENSE file for details.
 -->

<script lang="ts">
	import { goto } from '$app/navigation';
	import { base } from '$app/paths';
	import { PUBLIC_BASE_PATH } from '$env/static/public';
	import { onMount } from 'svelte';

	onMount(() => {
		goto(`${PUBLIC_BASE_PATH}/ja`);
	});
</script>
